import { Box, Fade, useOutsideClick } from "@chakra-ui/react";
import React from "react";
import styles from "../../styles/components/multiselect.module.css";

function MultiSelect({ options, onCheck, selectedOption }) {
  const [active, setActive] = React.useState(false);

  const ref = React.useRef(null);
  useOutsideClick({
    ref: ref,
    handler: () => setActive(false),
  });

  const onCheckItem = (index) => {
    const newOptions = [...options];
    newOptions[index].selected = !newOptions[index].selected;
    onCheck(newOptions);
  };

  return (
    <Box ref={ref} className={styles.main}>
      <Box onClick={() => setActive(!active)} className={styles.box}>
        <Box className={styles.labelSelected}>
          {selectedOption?.label || options?.[0]?.label || "Loading..."}
        </Box>
        <img
          className={active ? styles.activeIcon : styles.inActiveIcon}
          src="/new-ui/images/up-icon.svg"
          alt="Up"
        />
      </Box>
      <Box className={styles.container}>
        <Fade in={active}>
          {active && (
            <Box className={styles.options}>
              {options?.map((option, index) => {
                return (
                  <Box
                    onClick={() => onCheckItem(index)}
                    className={styles.option}
                    key={index}
                  >
                    {option?.headingLabel ? (
                      <Box className={styles.label}>{option.headingLabel}</Box>
                    ) : (
                      <>
                        <Box className={styles.check}>
                          <Box
                            bgColor={option.selected && "#000000"}
                            className={styles.checked}
                          ></Box>
                        </Box>
                        <Box className={styles.label}>{option.label}</Box>
                      </>
                    )}
                  </Box>
                );
              })}
            </Box>
          )}
        </Fade>
      </Box>
    </Box>
  );
}

export default MultiSelect;
