import { Box, Input, useOutsideClick } from "@chakra-ui/react";
import React from "react";
import styles from "../../styles/components/multiselect.module.css";
import { SearchIcon } from "@chakra-ui/icons";
function CustomInput({ onChangeHandler }) {
  return (
      <Box className={styles.searchInputBox}>
        <input
          focusBorderColor={"gray.500"}
          borderColor={"gray.500"}
          onChange={onChangeHandler}
          mt="5px"
          height="40px"
          placeholder="Search..."
          size="sm"
          borderRadius="5"
          className={styles.serchInput}
        />
        <SearchIcon boxSize={5} color="#000000"   className={styles.searchIcon}/>
      </Box>
  );
}

export default CustomInput;
