import { Box } from "@chakra-ui/react";
import React from "react";
import ServiceCard from "../organisms/ServiceCard";
import styles from "../../styles/components/cardBox.module.css";

function CardsBox({
	artists,
	selectedServiceTypes,
	homePageData,
	serviceTypes,
	keywordPage,
	isHairSalons,
}) {
	const getAsrtisrServices = (allServices) => {
		const artistServices = [];
		allServices?.forEach((artistservice) => {
			const service = selectedServiceTypes.find(
				(serviceType) => artistservice.serviceTypeId === serviceType?._id
			);
			if (service) {
				artistServices.push(artistservice);
			}
		});
		
		if (!artistServices?.length) {
			return allServices;
		}
	
		return artistServices;
	};

	const getMinPrice = (artistServices) => {
		const prices = [];
		artistServices.forEach((service) => {
			if (service?.price?.price) {
				prices.push(
					parseInt(service?.price?.price?.toString()?.split("$")?.join(""))
				);
			} else {
				prices.push(0);
			}
		});
		const sumOfprices = prices.reduce((acc, single) => acc + single, 0);

		return parseInt(sumOfprices / prices.length);
	};
	const getImages = (artistServices, isclaimedProfile) => {
		const images = [];
		artistServices?.forEach((serviceType) => {
			if (
				selectedServiceTypes?.find(
					(selectedServiceType) =>
						selectedServiceType?._id === serviceType?.serviceTypeId
				) ||
				homePageData ||
				isHairSalons
			) {
				if (serviceType?.images?.cover?.length) {
					serviceType?.images?.cover?.forEach((image) => {
						images.push(image);
					});
				}
			}
		});

		const fallbackImages = [
			"https://via.placeholder.com/350",
			"https://via.placeholder.com/350",
			"https://via.placeholder.com/350",
			"https://via.placeholder.com/350",
		];
		const imagesLenght = 5;
		if (images?.length >= imagesLenght) {
			return isclaimedProfile ? images : [];
		} else {
			const imagesLength = images?.length;
			for (let i = 0; i < imagesLenght - imagesLength; i++) {
				images.push(fallbackImages[i]);
			}
			return isclaimedProfile ? images : [];
		}
	};

	const getHomePageImages = (artistServices, item, isclaimed) => {
		const images = [];
		artistServices?.forEach((serviceType) => {
			if (serviceType?.serviceTypeId === item?._id) {
				if (serviceType?.images?.cover?.length) {
					serviceType?.images?.cover?.forEach((image) => {
						images.push(image);
					});
				}
			}
		});
		const isclaimedProfile = isclaimed ? true : false;

		const fallbackImages = [
			"https://via.placeholder.com/350",
			"https://via.placeholder.com/350",
			"https://via.placeholder.com/350",
			"https://via.placeholder.com/350",
		];
		if (images?.length >= 5) {
			return isclaimedProfile ? images : [];
		} else {
			const imagesLength = images?.length;
			for (let i = 0; i < 5 - imagesLength; i++) {
				images.push(fallbackImages[i]);
			}
			return isclaimedProfile ? images : [];
		}
	};

	return (
		<Box className={styles.cardBox}>
			{homePageData
				? serviceTypes?.map((item, index) => {
						const res = artists.filter((i) =>
							i?.servicesList?.find((c) => c.serviceTypeId === item?._id)
						);
						return res.map((required) => {
							const arrtistServices = getAsrtisrServices(
								required?.servicesList
							);
							const specific = arrtistServices.find(
								(ser) => ser.serviceTypeId === item?._id
							);
							const resServices = arrtistServices.filter(
								(ser) => ser._id != specific?._id
							);
							const filterByLengthStrin = resServices.filter((str) => {
								return str.name.length <= 10;
							});
							const lenghtyString = resServices.filter((str) => {
								return str.name.length > 10;
							});
							const artistStreet =
								required?.address?.street !== undefined
									? required?.address?.street + " ,"
									: "";
							const artistAdderesss = required?.address?.city || "Montreal";
							const artistState =
								required?.address?.state !== undefined &&
								required?.address?.state.length > 0
									? ", " + required?.address?.state
									: "";
							return (
								<ServiceCard
									avatar={required?.avatar}
									businessName={required?.businessName}
									userName={required?.userName}
								city={artistAdderesss}
									address={`${artistStreet} ${artistAdderesss} ${artistState}`}
									
									popular={true}
									services={[
										specific,
										...filterByLengthStrin,
										...lenghtyString,
									]}
									sponsored={required?.sponsoredArtist || false}
									minPrice={getMinPrice(required?.servicesList)}
									views={required?.pageViews?.count}
									coverImages={getHomePageImages(
										required?.servicesList,
										item,
										required?.isclaimed ? true : false
									)}
									id={required?._id}
									key={index}
									keywordPage={keywordPage}
									ethnicService={required?.ethnicArtistServices?.name}
									subscription={required?.subscription}
								/>
							);
						});
				  })
				: artists?.map((item, index) => {
						const artistStreet =
							item?.address?.street !== undefined
								? item?.address?.street + ","
								: "";
						const artistAdderesss = item?.address?.city || "Montreal";
						const artistState =
						item?.address?.state !== undefined &&
						item?.address?.state.length > 0
							? ", " + item?.address?.state
							: "";
							
						return (
							<ServiceCard
								avatar={item?.avatar}
								businessName={item?.businessName}
								userName={item?.userName}
								city={artistAdderesss}
								address={`${artistStreet} ${artistAdderesss} ${artistState}`}
								popular={true}
								services={getAsrtisrServices(item?.servicesList)}
								sponsored={item?.sponsoredArtist || false}
								minPrice={getMinPrice(item?.servicesList)}
								views={item?.pageViews?.count}
								coverImages={getImages(
									item?.servicesList,
									item?.isclaimed ? true : false
								)}
								id={item?._id}
								key={index}
								ethnicService={item?.ethnicArtistServices?.name}
								subscription={item?.subscription}
							/>
						);
				  })}
		</Box>
	);
}

export default CardsBox;
