import { Box, Collapse, useDisclosure } from "@chakra-ui/react";
import React from "react";
import styles from "../../styles/components/selectAccordion.module.css";

function SelectAccordion({
  options,
  label,
  expanded,
  index,
  onExpand,
  onCheck,
}) {
  const onCheckItem = (index) => {
    const newOptions = [...options];
    newOptions[index].selected = !newOptions[index].selected;
    onCheck(newOptions);
  };

  return (
    <Box className={styles.main}>
      <Collapse in={expanded === index}>
        <Box className={styles.options}>
          {options?.map((option, index) => {
            return (
              <Box
                onClick={() => onCheckItem(index)}
                className={styles.option}
                key={index}
              >
                {option?.headingLabel ? (
                  <>
                    <Box className={styles.label}>{option.headingLabel}</Box>
                  </>
                ) : (
                  <>
                    <Box className={styles.check}>
                      <Box
                        bgColor={option.selected && "#000000"}
                        className={styles.checked}
                      ></Box>
                    </Box>
                    <Box className={styles.label}>{option.label}</Box>
                  </>
                )}
              </Box>
            );
          })}
        </Box>
      </Collapse>
      <Box onClick={onExpand} className={styles.box}>
        <Box className={styles.labelSelected}>{label}</Box>
        <img
          className={
            expanded === index ? styles.activeIcon : styles.inActiveIcon
          }
          src="/new-ui/images/up-icon.svg"
          alt="Up"
        />
      </Box>
    </Box>
  );
}

export default SelectAccordion;
