import { Box } from "@chakra-ui/react";
import React from "react";
import styles from "../../styles/components/heroSection.module.css";
import UiButton from "../atoms/UiButton";

function HeroSection({ data }) {

  return (
    <Box>
      {/* Desktop Screens */}
      <Box className={styles.main}>
        <Box className={styles.left}>
          <Box className={styles.blueText} as="p">
            Discover  
          </Box>
          <Box className={styles.mainCont}>
            <Box as="h1" className={styles.leftHeading}>
              {data?.heroSectionData?.topLeftHeading}
            </Box>
            <Box className={styles.middleBox}>
              <Box className={styles.bgBox}>
                <img
                  className={styles.imgBg}
                  src="/new-ui/images/images-bg.png"
                />
                <Box className={styles.centerImageBox}>
                  <Box as="h1" className={styles.leftHeading2}>
                    {data?.heroSectionData?.topLeftHeading}
                  </Box>
                  <Box className={styles.rightHeadingMain2} as="p">
                    {data?.heroSectionData?.bottomRightHeadingUpperLine}
                    <br />
                    {data?.heroSectionData?.bottomRightHeadingBottomLine}
                  </Box>
                  <img className={styles.upperImage} src={data?.heroSectionData?.centerImage} />
                </Box>
              </Box>
            </Box>
            <Box className={styles.rightHeadingMain} as="p">
              {data?.heroSectionData?.bottomRightHeadingUpperLine}
              <br />
              {data?.heroSectionData?.bottomRightHeadingBottomLine}
            </Box>
          </Box>
          <Box as="p" className={styles.description}>
            {data?.heroSectionData?.description}
          </Box>
          {/*<Box h="66px" my="50px" w="360px">
            <UiButton hFull label={"Find a Beauty Professional"} />
          </Box>*/}
          <Box className={styles.leftBtmBox}>
            <img
              className={styles.leftImgUppr}
              src={data?.heroSectionData?.bottomLeftImage}
              alt="Image"
            />
            <img
              className={styles.logo}
              src="/new-ui/images/round-logo.png"
              alt="CoverClap"
            />
          </Box>
        </Box>

        <Box className={styles.right}>
          <Box className={styles.rightImageBox}>
            <img className={styles.rigthImg} alt="img" src={data?.heroSectionData.topRightImage} />
            <img
              className={styles.rightStar}
              src="/new-ui/images/smash.png"
              alt="CoverClap"
            />
          </Box>
        </Box>
        <Box className={styles.right1}>
          <Box className={styles.leftBtmBox1}>
            <img
              className={styles.leftImg1}
              src={data?.heroSectionData?.bottomLeftImage}
              alt="Image"
            />
            <img
              className={styles.logo}
              src="/new-ui/images/round-logo.png"
              alt="CoverClap"
            />
          </Box>
          <Box className={styles.rightImageBox}>
            <img className={styles.rigthImg} alt="img" src={data?.heroSectionData?.topRightImage} />
            <img
              className={styles.rightStar}
              src="/new-ui/images/smash.png"
              alt="CoverClap"
            />
          </Box>
        </Box>
      </Box>

      {/* Small Screens */}

      <Box className={styles.mainMobile}>
        <Box className={styles.blueText} as="p">
          Discover  
        </Box>
        <Box as="h1" className={styles.leftHeading}>
          {data?.heroSectionData?.mobileHeading}
        </Box>
        {/*<Box margin={"auto"} h="54px" mt="16px" maxW="286px">
          <UiButton hFull label={"Find a Beauty Professional"} />
        </Box>*/}
        <Box className={styles.middleBox}>
          <Box className={styles.bgBox}>
            <img className={styles.imgBg} src="/new-ui/images/images-bg.png" />
            <Box className={styles.centerImageBox1}>
              <img className={styles.upperImage} src={data?.heroSectionData?.centerImage} />
            </Box>
          </Box>
        </Box>
        <Box className={styles.lastBox}>
          <Box className={styles.leftBox}>
            <img
              className={styles.leftBoxImg1}
              src={data?.heroSectionData?.bottomLeftImage}
              alt="Image"
            />
            <img
              className={styles.logo1}
              src="/new-ui/images/round-logo.png"
              alt="CoverClap"
            />
          </Box>
          <Box className={styles.rightBox}>
            <img className={styles.rigthImg1} alt="img" src={data?.heroSectionData?.topRightImage} />
            <img
              className={styles.rightStar1}
              src="/new-ui/images/smash.png"
              alt="CoverClap"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default HeroSection;
