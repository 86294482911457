import { Box } from "@chakra-ui/react";
import React from "react";
import styles from "../../styles/components/filterbar.module.css";
import CustomInput from "../atoms/CustomInput";
import MultiSelect from "../atoms/MultiSelect";
import UiButton from "../atoms/UiButton";
import FilterBox from "./FilterBox";

function Filterbar({
  orderbyOptions,
  showByOptions,
  serviceTypeOptions,
  allOptions,
  setFilters,
  clearFilters,
  applyFilters,
}) {
  const [showSmallFilter, setShowSmallFilter] = React.useState(false);
  const onChecked = (items, option) => {
    const tempArr = [];
    allOptions.map((item) => {
      if (item.label === option.label) {
        tempArr.push({ ...item, options: items });
      } else {
        tempArr.push(item);
      }
    });
    setFilters(tempArr);
  };
  const inputHandler = (val) => {
    const filter = allOptions.filter((i) => i?.label !== "Search");
    const rearrange = [
      {
        label: "Search",
        options: val,
      },
      ...filter,
    ];
    setFilters(rearrange);
  };
  const selectedServiceType = serviceTypeOptions?.options.filter(
    (item) => item.selected
  )[0];
  return (
    <Box>
      <Box className={styles.filterbar}>
        <Box className={styles.selectors}>
          <Box className={styles.item}>
            <Box className={styles.textLabel} as="p">
              {/* Show */}
            </Box>
            <Box className={styles.selectCont}>
              <CustomInput
                onChangeHandler={(e) => inputHandler(e.target.value)}
              />
            </Box>
          </Box>
          <Box className={styles.item}>
            <Box className={styles.textLabel} as="p">
              Order by
            </Box>
            <Box className={styles.selectCont}>
              <MultiSelect
                options={orderbyOptions?.options}
                onCheck={(checked) => onChecked(checked, orderbyOptions)}
              />
            </Box>
          </Box>
          <Box className={styles.item}>
            <Box className={styles.textLabel} as="p">
              Services
            </Box>
            <Box className={styles.selectCont}>
              <MultiSelect
                selectedOption={selectedServiceType}
                options={serviceTypeOptions?.options}
                onCheck={(checked) => onChecked(checked, serviceTypeOptions)}
              />
            </Box>
          </Box>
        </Box>
        <Box className={styles.rightBox}>
          <Box h="54px" className={styles.btn}>
            <UiButton hFull onClick={applyFilters} label={"Apply filter"} />
          </Box>
          <Box onClick={clearFilters} className={styles.delBtn}>
            <img src="/new-ui/images/del-icon.svg" alt="Del Icon" />
            <Box as="span">Clear Filter</Box>
          </Box>
        </Box>
      </Box>

      <Box className={styles.filterbarMobile}>
        <Box className={styles.mobileFilterBarContainer}>
          <Box>
            <CustomInput
              onChangeHandler={(e) => {
              }}
            />
          </Box>
          <Box
            visibility={!showSmallFilter ? "visible" : "hidden"}
            as="button"
            onClick={() => setShowSmallFilter(true)}
            className={styles.filterBtn}
          >
            <img src="/new-ui/images/filter.svg" alt="filter" />
            <Box as="p">Order by</Box>
          </Box>
        </Box>
        <Box className={styles.box}>
          <FilterBox
            applyFilters={applyFilters}
            clearFilters={clearFilters}
            onCheck={(checked) => onChecked(checked, showByOptions)}
            options={allOptions}
            setOptions={setFilters}
            active={showSmallFilter}
            setActive={setShowSmallFilter}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Filterbar;
