export function getUrl(stylistId, imgName) {
    let cdnDirstribution = "https://dqkfop21x0g61.cloudfront.net/";
    let imageRequest = JSON.stringify({
        "bucket": "zorastreet",
        "key": `providers/${stylistId}/img/${imgName}`,
        "edits": {
            "resize": {
                "width": 300,
                "height": 300,
                "fit": "cover"
            }
        }
    });


    return cdnDirstribution + Buffer.from(imageRequest).toString('base64');
}




