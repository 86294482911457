import { Badge, Box, Stack, Image, Avatar } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { getUrl } from "../../hairstylistsData";
import styles from "../../styles/components/serviceCard.module.scss";
import { useWindowSize } from "../../hooks/useWindowSize";

import UiButton from "../atoms/UiButton";
import Link from "next/link";
function ServiceCard({
  avatar,
  businessName,
  userName,
  subscription,
  address,
  popular,
  sponsored,
  views,
  id,
  coverImages,
  services,
  minPrice,
  ethnicService,
  city,
}) {
  const router = useRouter();
  function formatTime(minutes) {
    var hours = Math.floor(minutes / 60);
    var minutes_remainder = minutes % 60;

    if (hours === 0 && minutes_remainder === 0) {
      return "";
    } else if (hours === 0) {
      return minutes_remainder + "min";
    } else if (minutes_remainder === 0) {
      return hours + "h";
    } else {
      return hours + "h" + minutes_remainder;
    }
  }

  const refactorCity = city?.toLowerCase();
  const [imageLimit, setImageLimit] = useState(4);
  useEffect(() => {
    const card = document.getElementById(id);
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        if (cr.width < 600) {
          setImageLimit(2);
        } else if (cr.width < 1200) {
          setImageLimit(3);
        } else if (cr.width > 1200) {
          setImageLimit(4);
        }
      }
    });
    if (card) {
      observer.observe(card);
    }

    return () => {
      if (card) {
        observer.unobserve(card);
      }
    };
  }, []);
  const width = useWindowSize().width;
  let trimCharacter = width > 440 ? 40 : 22;
  return subscription !== "premium" ? (
    <a href={`/a/${refactorCity}/${userName}`}>
      <Box id={id}>
        <Box mt="27px" className={styles.main}>
          {sponsored && (
            <Box className={styles.sponsred}>
              <img src="/new-ui/images/badge.svg" alt="Badge" /> Sponsored
            </Box>
          )}
          {/*popular && (
          <Box className={styles.popular}>
            Most popular <img src="/new-ui/images/fire.svg" alt="Badge" />
          </Box>
        )*/}
          <Box className={styles.upperRow}>
            <Box className={styles.rowLeft}>
              <Box className={styles.avatarBox}>
                <Avatar
                  boxSize={"100%"}
                  src={
                    avatar?.slice(0, 4) === "http" ? avatar : getUrl(id, avatar)
                  }
                  name={businessName}
                />
              </Box>
              <Box className={styles.servicesMother}>
                <Box className={styles.nameCont}>
                  {/*<img src="/new-ui/images/profile.svg" alt="Profile" />*/}
                  <Box as="p">{businessName || ""}</Box>
                </Box>
                <Box className={styles.locCont}>
                  <img src="/new-ui/images/location.svg" alt="Location" />
                  <Box as="p">{address}</Box>
                </Box>

                <Stack className={styles.services}>
                  {ethnicService && (
                    <box className={styles.ethnicBadge}>{ethnicService}</box>
                  )}
                  {services?.map((item, index) => {
                    return (
                      <box
                        className={styles.badge}
                        style={{ marginTop: 0 }}
                        key={index}
                      >
                        {item?.name?.slice(0, 35)}
                      </box>
                    );
                  })}
                  {services?.length > 2 && (
                    <Box className={styles.plusNum} as="p">
                      +{services?.length - 2}
                    </Box>
                  )}
                </Stack>
              </Box>
            </Box>
            <Box className={styles.rowRightNew}>
              <Box className={styles.distance}>
                3mi
                <Box as="p" className={styles.d}>
                  Distance
                </Box>
              </Box>
              <Box className={styles.startFrom}>
                ${minPrice}
                <Box as="p" className={styles.d}>
                  AVG price
                </Box>
              </Box>
            </Box>
            <Box className={styles.servicesMbl}>
              {ethnicService && (
                <Box className={styles.ethnicBadge}>{ethnicService}</Box>
              )}
              {services?.map((item, index) => {
                return (
                  <Box key={index} className={styles.badge}>
                    {item?.name?.slice(0, 35)}
                  </Box>
                );
              })}

              {services?.length > 3 && (
                <Box className={styles.plusNum} as="p">
                  +{services?.length - 3}
                </Box>
              )}
            </Box>
            {/* <Box className={styles.rowRight}>
            <Box className={styles.rightLeft}>
              <Box className={styles.price}>
                <Box as='p'>${minPrice}</Box>
                <Box className={styles.min}>Avg Price</Box>
              </Box>
              <Box className={styles.eye}>
                <img src='/new-ui/images/eye.svg' alt='Location' />
                <Box as='p'>{views || 0} Views</Box>
              </Box>
            </Box>
            <a href={`/${refactorCity}/${userName}`}>
              <Box className={styles.rightBtn}>
                <UiButton hFull bgColor={"#000000"} label={"View profile"} />
              </Box>
            </a>
          </Box> */}
          </Box>
          <Box className={styles.cardBottom}>
            <div className={styles.serviceBars}>
              {services?.slice(0, 3)?.map((item, index) => {
                const price = item?.price?.price.includes("$")
                  ? item?.price?.price
                  : `$${item?.price?.price}`;
                return (
                  <div className={styles.unpremiumbar} key={index + "bar"}>
                    <div className={styles.left}>
                      <h6 className={styles.serviceName}>
                        {item?.name.slice(0, trimCharacter)}
                        {item?.name.length > trimCharacter && "...."}
                      </h6>
                      <p className={styles.serviceDuration}>
                        {formatTime(item?.price?.duration)}
                      </p>
                    </div>
                    <div className={styles.right}>
                      {`${item?.price?.priceType === "from" ? "From " : ""} 
                    ${price}`}
                    </div>
                  </div>
                );
              })}

              <p className={styles.seeAll}>See all services</p>
            </div>

            <Box className={styles.coverImages}>
              {imageLimit &&
                coverImages?.slice(0, imageLimit)?.map((item, index) => {
                  return (
                    <Box className={styles.imgCont} key={index}>
                      <Image
                        fallbackSrc="https://via.placeholder.com/350"
                        height={"100%"}
                        objectFit="cover"
                        borderRadius="8px"
                        src={
                          item?.slice(0, 4) === "http" ? item : getUrl(id, item)
                        }
                        alt="Location"
                      />
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Box>
    </a>
  ) : (
    <a href={`/a/${refactorCity}/${userName}`}>
      <Box id={id} className={styles.primiumContainer}>
        <Box className={styles.bgPink}></Box>
        <Box className={styles.bg2}></Box>
        <Box className={styles.primiumMain}>
          {sponsored && (
            <Box className={styles.sponsred}>
              <img src="/new-ui/images/badge.svg" alt="Badge" /> Sponsored
            </Box>
          )}
          {/*popular && (
          <Box className={styles.popular}>
            Most popular <img src="/new-ui/images/fire.svg" alt="Badge" />
          </Box>
        )*/}
          <Box className={styles.upperRowPrimium}>
            <Box className={styles.rowLeftPrimium}>
              <Box className={styles.AvatarGetis}></Box>
              <Box className={styles.avatarBoxPrimium}>
                <Avatar
                  boxSize={"100%"}
                  src={
                    avatar?.slice(0, 4) === "http" ? avatar : getUrl(id, avatar)
                  }
                  name={businessName}
                />
              </Box>
              <Box className={styles.servicesMother}>
                <Box className={styles.nameCont}>
                  <img src="/new-ui/images/profile.svg" alt="Profile" />
                  <Box as="p">{businessName || ""}</Box>
                </Box>
                <Box className={styles.locCont}>
                  <img src="/new-ui/images/location.svg" alt="Location" />
                  <Box as="p">{address}</Box>
                </Box>
                <Box className={styles.services}>
                  {ethnicService && (
                    <Box className={styles.ethniBadge}>{ethnicService}</Box>
                  )}
                  {services?.map((item, index) => {
                    return (
                      <box key={index} className={styles.badge}>
                        {item?.name?.slice(0, 35)}
                      </box>
                    );
                  })}

                  {services?.length > 3 && (
                    <Box className={styles.plusNum} as="p">
                      +{services?.length - 3}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box className={styles.rowRightNew}>
              <Box className={styles.distance}>
                3mi
                <Box as="p" className={styles.d}>
                  Distance
                </Box>
              </Box>
              <Box className={styles.startFrom}>
                ${minPrice}
                <Box as="p" className={styles.d}>
                  AVG price
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={styles.servicesMbl}>
            {ethnicService && (
              <Box className={styles.ethnicBadge}>{ethnicService}</Box>
            )}
            {services?.map((item, index) => {
              return (
                <Box key={index} className={styles.badge}>
                  {item?.name?.slice(0, 35)}
                </Box>
              );
            })}

            {services?.length > 3 && (
              <Box className={styles.plusNum} as="p">
                +{services?.length - 3}
              </Box>
            )}
          </Box>
          <Box className={styles.cardBottom}>
            <div className={styles.serviceBars}>
              {services?.slice(0, 3)?.map((item, index) => {
                const price = item?.price?.price.includes("$")
                  ? item?.price?.price
                  : `$${item?.price?.price}`;

                return (
                  <div className={styles.bar} key={index + "bar"}>
                    <div className={styles.left}>
                      <h6 className={styles.serviceName}>{item?.name}</h6>
                      <p className={styles.serviceDuration}>
                        {formatTime(item?.price?.duration)}
                      </p>
                    </div>
                    <div className={styles.right}>
                      {`${item?.price?.priceType === "from" ? "From " : ""} 
                    ${price}`}
                    </div>
                  </div>
                );
              })}

              <p className={styles.seeAll}>See all services</p>
            </div>

            <Box className={styles.coverImages}>
              {imageLimit &&
                coverImages?.slice(0, imageLimit)?.map((item, index) => {
                  return (
                    <Box className={styles.imgCont} key={index}>
                      <Image
                        fallbackSrc="https://via.placeholder.com/350"
                        height={"100%"}
                        objectFit="cover"
                        borderRadius="8px"
                        src={
                          item?.slice(0, 4) === "http" ? item : getUrl(id, item)
                        }
                        alt="Location"
                      />
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Box>
    </a>
  );
}

export default ServiceCard;
