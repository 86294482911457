import { Box, Fade, useOutsideClick } from "@chakra-ui/react";
import React from "react";
import styles from "../../styles/components/filterbox.module.css";
import SelectAccordion from "../atoms/SelectAccordion";
import UiButton from "../atoms/UiButton";

function FilterBox({
  active,
  setActive,
  options,
  setOptions,
  clearFilters,
  applyFilters,
}) {
  const [expanded, setExpanded] = React.useState(null);
  const ref = React.useRef(null);
  useOutsideClick({
    ref: ref,
    handler: () => setActive(false),
  });
  const onChecked = (checked, option) => {
    const tempArr = [];
    options.map((item) => {
      if (item.label === option.label) {
        tempArr.push({ ...item, options: checked });
      } else {
        tempArr.push(item);
      }
    });
    setOptions(tempArr);
  };

  const onFilter = () => {
    applyFilters();
    setActive(false);
  };
  return (
    <Box zIndex={active ? 2 : -1} position={"relative"} w="100%">
      <Fade className={styles.main} in={active} ref={ref}>
        {active && (
          <Box>
            <Box className={styles.boxHeader}>
              <Box as="p" className={styles.headerHeading}>
                {expanded !== null ? options[expanded].label : "Filters"}
              </Box>
              <Box onClick={() => setActive(false)}>
                <img src="/new-ui/images/close.svg" alt="close" />
              </Box>
            </Box>
            <Box className={styles.body}>
              {options.map((option, index) => {
                if (option?.options?.length > 0) {
                  return (
                    <SelectAccordion
                      key={index}
                      onExpand={() =>
                        setExpanded(index === expanded ? null : index)
                      }
                      expanded={expanded}
                      onCheck={(items) => onChecked(items, option)}
                      index={index}
                      label={option.label}
                      options={option.options}
                      setOptions={setOptions}
                    />
                  );
                } else {
                  return <></>;
                }
              })}
            </Box>
            <Box className={styles.boxFooter}>
              <Box className={styles.btn}>
                <UiButton onClick={onFilter} label={"Apply filter"} />
              </Box>
              <Box onClick={clearFilters} className={styles.delBtn}>
                <img src="/new-ui/images/del-icon.svg" alt="Del Icon" />
                <Box as="span">Clear Filter</Box>
              </Box>
            </Box>
          </Box>
        )}
      </Fade>
    </Box>
  );
}

export default FilterBox;
